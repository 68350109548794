// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.bg-c-purple {
  background: linear-gradient(45deg, #ab47bc, #ba68c8);
}

.card {
  border-radius: 5px;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.card .card-block {
  padding: 10px;
  color: #fff;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

@media (max-width: 640px) {
  .card {
    border-radius: 4px;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/analytics/Analytics.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,kBAAkB;EAClB,wEAAwE;EACxE,uBAAuB;EACvB,mBAAmB;EAEnB,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,kBAAkB;IAClB,uEAAuE;IACvE,mBAAmB;EACrB;AACF","sourcesContent":[".order-card {\n  color: #fff;\n}\n\n.bg-c-blue {\n  background: linear-gradient(45deg, #4099ff, #73b4ff);\n}\n\n.bg-c-green {\n  background: linear-gradient(45deg, #2ed8b6, #59e0c5);\n}\n\n.bg-c-yellow {\n  background: linear-gradient(45deg, #ffb64d, #ffcb80);\n}\n\n.bg-c-pink {\n  background: linear-gradient(45deg, #ff5370, #ff869a);\n}\n\n.bg-c-purple {\n  background: linear-gradient(45deg, #ab47bc, #ba68c8);\n}\n\n.card {\n  border-radius: 5px;\n  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);\n  border: none !important;\n  margin-bottom: 20px;\n  -webkit-transition: all 0.3s ease-in-out;\n  transition: all 0.3s ease-in-out;\n}\n\n.card .card-block {\n  padding: 10px;\n  color: #fff;\n}\n\n.order-card i {\n  font-size: 26px;\n}\n\n.f-left {\n  float: left;\n}\n\n.f-right {\n  float: right;\n}\n\n@media (max-width: 640px) {\n  .card {\n    border-radius: 4px;\n    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
