import React from "react";
import Analytics from "../../components/analytics/Analytics";

function Dashboard() {
  return (
    <div className="">
      <div>Dashboard</div>
      <Analytics />
    </div>
  );
}

export default Dashboard;
