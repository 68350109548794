import React, { useEffect, useRef, useState } from "react";
import { FaTimes, FaWallet } from "react-icons/fa";
import { Button, Form } from "react-bootstrap";
import {
  getTransactionFee,
  getWallet,
  updateTransactionFee
} from "../../../utils/services/transactions";

const Wallet = ({ showWallet, setShowWallet, settingsIconRef }) => {
  const walletRef = useRef(null);
  const [walletBalance, setWalletBalance] = useState(500);
  const [transactionFees, setTransactionFees] = useState({
    event_fee_percentage: 0,
    top_up_percentage: 0,
    transfer_percentage: 0,
    withdraw_percentage: 0
  });

  const [selectedFeeType, setSelectedFeeType] = useState("event_fee_percentage");
  const [newTransactionFee, setNewTransactionFee] = useState("");

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        let user = localStorage.getItem("user");
        if (user) {
          user = JSON.parse(user);
        }
        const response = await getWallet({ id: user.id, ownertype: "user" });
        if (response?.type === "success") {
          setWalletBalance(response.result.balance);
        } else {
          console.error("Failed to fetch wallet:", response);
        }
      } catch (error) {
        console.error("Error fetching wallet:", error);
      }
    };

    const fetchTransactionFees = async () => {
      try {
        const response = await getTransactionFee();
        if (response?.data?.length > 0) {
          setTransactionFees(response.data[0]);
        } else {
          console.error("Failed to fetch transaction fees:", response);
        }
      } catch (error) {
        console.error("Error fetching transaction fees:", error);
      }
    };

    fetchWallet();
    fetchTransactionFees();
  }, []);

  const updateFee = async () => {
    if (
      !selectedFeeType ||
      newTransactionFee === "" ||
      newTransactionFee < 0 ||
      newTransactionFee > 100
    ) {
      alert("Please select a fee type and enter a valid percentage (0-100).");
      return;
    }

    try {
      const updatedFeeData = { [selectedFeeType]: parseFloat(newTransactionFee) };
      const result = await updateTransactionFee(updatedFeeData);
      console.log("Updated Fee Response:", result);

      // Update UI instantly
      setTransactionFees((prevFees) => ({ ...prevFees, ...updatedFeeData }));
      setNewTransactionFee("");
      alert("Transaction fee updated successfully!");
    } catch (error) {
      console.error("Error updating transaction fee:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        walletRef.current &&
        !walletRef.current.contains(event.target) &&
        !settingsIconRef.current.contains(event.target)
      ) {
        setShowWallet(false);
      }
    };

    if (showWallet) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showWallet, settingsIconRef]);

  if (!showWallet) return null;

  return (
    <div
      ref={walletRef}
      className="wallet-container shadow-lg p-3 bg-white rounded"
      style={{
        position: "absolute",
        top: "10px",
        left: settingsIconRef.current?.offsetLeft + 40 || "50px",
        width: "400px",
        border: "1px solid #ddd",
        zIndex: 1000,
        animation: "fadeIn 0.3s ease-in-out"
      }}>
      {/* Wallet Header */}
      <div className="d-flex justify-content-between align-items-center border-bottom pb-2">
        <h5 className="text-primary m-0">
          <FaWallet className="me-2" />
          My Wallet
        </h5>
        <FaTimes
          className="text-danger cursor-pointer"
          style={{ cursor: "pointer", fontSize: "1.2rem" }}
          onClick={() => setShowWallet(false)}
        />
      </div>

      {/* Wallet Balance */}
      <div className="mt-3 text-center">
        <h6 className="text-secondary">Current Balance</h6>
        <h4 className="text-success fw-bold">${walletBalance.toFixed(2)}</h4>
      </div>

      {/* Transaction Fees Display */}
      <div className="mt-3">
        <h6 className="text-secondary text-center">Transaction Fees</h6>
        <ul className="list-group">
          {Object.entries(transactionFees)
            .filter(([key]) => key !== "_id" && key !== "__v")
            .map(([key, value]) => (
              <li key={key} className="list-group-item d-flex justify-content-between">
                <span className="fw-bold">{key.replace(/_/g, " ")}:</span>
                <span>{value}%</span>
              </li>
            ))}
        </ul>
      </div>

      {/* Transaction Fee Selection */}
      <Form.Group className="mt-3">
        <Form.Label className="fw-bold">Select Fee Type</Form.Label>
        <Form.Select value={selectedFeeType} onChange={(e) => setSelectedFeeType(e.target.value)}>
          <option value="event_fee_percentage">Event Fee Percentage</option>
          <option value="top_up_percentage">Top Up Percentage</option>
          <option value="transfer_percentage">Transfer Percentage</option>
          <option value="withdraw_percentage">Withdraw Percentage</option>
        </Form.Select>
      </Form.Group>

      {/* Transaction Fee Input */}
      <Form.Group className="mt-3">
        <Form.Label className="fw-bold">New Transaction Fee (%)</Form.Label>
        <Form.Control
          type="number"
          value={newTransactionFee}
          onChange={(e) => setNewTransactionFee(e.target.value)}
          min="0"
          max="100"
          placeholder="Enter new fee percentage"
        />
      </Form.Group>

      {/* Action Buttons */}
      <div className="d-flex justify-content-between mt-3">
        <Button variant="primary" className="w-100 me-2" onClick={updateFee}>
          Update Fee
        </Button>
      </div>
    </div>
  );
};

export default Wallet;
