import React, { useEffect, useState } from "react";

import { RiTeamFill, RiOrganizationChart, RiPassportFill } from "react-icons/ri";
import { MdEmojiEvents, MdSportsEsports } from "react-icons/md";

import { toast } from "react-toastify";

import { getRequest } from "../../utils/axios-methods";
import "./Analytics.css";

const Analytics = () => {
  const [stats, setStats] = useState([]);
  const [error, setError] = useState(false);

  const fetchStats = async () => {
    try {
      const res = await getRequest("/admin/stats");
      setStats(res.stats);
    } catch (error) {
      toast.error("Something went wrong!!");
      setError(true);
    }
  };
  useEffect(() => {
    setError(false);
    fetchStats();
  }, []);

  return (
    <div style={{ marginTop: "1rem", width: "100%" }}>
      {!error && (
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card bg-c-blue order-card">
              <div className="card-block">
                <p className="fs-5 fw-bold">
                  Teams
                  <span className="f-right">
                    <RiTeamFill size={25} />
                  </span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Total<span className="f-right">{stats?.teams?.total || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Active<span className="f-right">{stats?.teams?.active || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Deleted<span className="f-right">{stats?.teams?.deleted || 0}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card bg-c-green order-card">
              <div className="card-block">
                <p className="fs-5 fw-bold">
                  Events
                  <span className="f-right">
                    <MdEmojiEvents size={25} />
                  </span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Total<span className="f-right">{stats?.events?.total || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Complete<span className="f-right">{stats?.events?.complete || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Incomplete<span className="f-right">{stats?.events?.incomplete || 0}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card bg-c-yellow order-card">
              <div className="card-block">
                <p className="fs-5 fw-bold">
                  Players
                  <span className="f-right">
                    <MdSportsEsports size={25} />
                  </span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Total<span className="f-right">{stats?.players?.total || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Active<span className="f-right">{stats?.players?.active || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Deleted<span className="f-right">{stats?.players?.deleted || 0}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card bg-c-pink order-card">
              <div className="card-block">
                <p className="fs-5 fw-bold">
                  Oranizers
                  <span className="f-right">
                    <RiOrganizationChart size={25} />
                  </span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Total<span className="f-right">{stats?.organizers?.total || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Active<span className="f-right">{stats?.organizers?.active || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Deleted<span className="f-right">{stats?.organizers?.deleted || 0}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card bg-c-purple  order-card">
              <div className="card-block">
                <p className="fs-5 fw-bold">
                  Sponsors
                  <span className="f-right">
                    <RiPassportFill size={25} />
                  </span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Total<span className="f-right">{stats?.sponsors?.total || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Active<span className="f-right">{stats?.sponsors?.active || 0}</span>
                </p>
                <p className="m-b-0" style={{ marginBottom: 0 }}>
                  Deleted<span className="f-right">{stats?.sponsors?.deleted || 0}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
