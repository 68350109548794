import { getRequest, postRequest, putRequest } from "../axios-methods";

/**
 * Get all transactions
 * @param {String} status ['pending' , 'complete']
 * @returns
 */
export const getAllTransactions = async (payload, offset, limit) => {
  try {
    const result = await postRequest(
      `/transaction/getpendingtransactions?skip=${offset}&limit=${limit}`,
      payload
    );
    return result;
  } catch (error) {
    return await error;
  }
};

/**
 * Update transaction status
 * @param {String} transactionId
 * @param {String} status ['rejected' , 'completed']
 * @returns
 */
export const approveRejectTransaction = async (payload) => {
  try {
    const result = await postRequest(`/transaction/updatetransactionstatus`, payload);
    return result;
  } catch (error) {
    return await error;
  }
};

export const updateTransactionFee = async (payload) => {
  try {
    const result = await putRequest(`/transaction/updatewithdrawalpercentage`, payload);
    return result;
  } catch (error) {
    console.error("Error updating transaction fee:", error);
    return error;
  }
};
export const getTransactionFee = async () => {
  try {
    const result = await getRequest(`/transaction/gettransactionsettings`);
    return result;
  } catch (error) {
    console.error("Error getting transaction fee:", error);
    return error;
  }
};

export const getWallet = async (payload) => {
  try {
    const result = await postRequest(`/transaction/getplayerwallet`, payload);
    return result;
  } catch (error) {
    console.error("Error getting wallet:", error);
    return await error;
  }
};
